<template>
  <div>
  <v-container class='pb-0'>
    <v-col
      class='py-1'
      cols=12
      v-for='(notification, index) in notifycations'
      :key='index'
    >
      <p @click='openDetail(index)' class='text-subtitle-2 text-truncate important_notification text-decoration-underline'>
        <span class='required-label mr-1 '>
          重要
        </span>
        {{ notification.title }}
      </p>
    </v-col>
  </v-container>
  <v-dialog scrollable v-model="dialog" max-width="640">
    <v-card>
      <v-card-title>
        <span class='text-h5'>{{ selectNotification.title }}</span>
      </v-card-title>
      <v-card-text class='pb-0'>
        <v-container>
          {{ selectNotification.detail }}
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color='grey darken-1' dark outlined @click='dialog = false'>
          <v-icon class='pr-1'>mdi-close</v-icon>
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>
<script>
import util from '../util';

export default {
  name: 'important-notification',
  props: {
    shop: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    dialog: false,
    notifycations: [],
    selectNotification: {},
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.notifycations = await util.callCloudFunction('getImportantNotification');
    },
    openDetail(index) {
      this.selectNotification = this.notifycations[index];
      this.dialog = true;
    },
  },
};
</script>
<style>
.important_notification {
  cursor: pointer;
}
</style>
