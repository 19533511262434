<template>
  <div class='pa-6'>
    <v-sheet tile height='30' class='d-flex'>
      <h2>予約申し込み</h2>
    </v-sheet>
    <important-notification></important-notification>
    <v-container class='pb-0'>
      <v-row justify='center' dense>
        <v-col cols=12 lg=3 md=4 sm=6>
          <v-select
            v-model='selectShop'
            item-text='name'
            item-value='id'
            :items='shops'
            dense
            outlined
            hide-details
            label='店舗'
            class='ma-2'
            return-object
          ></v-select>
        </v-col>
        <v-col cols=12 lg=3 md=4 sm=6>
          <v-sheet
            tile
            height="60"
            class="d-flex"
          >
          <Datepicker :selectDay='selectDay' @input='selectDay = $event'></Datepicker>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <!-- main -->
    <v-container>
      <v-row justify='center'>
        <v-col cols=12 lg=4 md=6 sm=6
          v-for='(emptySeat, key) in emptyInfo'
          :key='key'
        >
          <v-sheet dense class='text-caption mb-2'>
            <v-icon small color='green darken-2'>mdi-square-rounded</v-icon>・・レッスン
            <v-icon class='ml-3' small color='blue darken-2'>mdi-square-rounded</v-icon>・・フリーレンジ
          </v-sheet>
          <v-card class='mb-8' max-width='500'>
            <v-card-title class='white--text blue light-2 text-body-2 pa-2'>
              <v-btn text small class='white--text px-1' @click='prev()' >
                <v-icon>mdi-chevron-left</v-icon>
                前日
              </v-btn>
              <v-spacer></v-spacer>
              <div v-if='emptySeat.byTime !== "empty"'>
              {{ changeFormat(emptySeat.schedule.date) }}
              </div>
              <div v-else>
              {{ getSelectDay(key) }}
              </div>
              <v-spacer></v-spacer>
              <v-btn text small class='white--text px-1' @click='next()' >
                翌日
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class='pt-4' v-if='emptySeat.byTime !== "empty"'>
              担当コーチ {{ groupByInstructor(emptySeat.schedule.instructors) }}
            </v-card-text>
            <v-card-text class='pt-4' v-else>
              担当コーチ 未定
            </v-card-text>
            <v-divider></v-divider>
            <div v-if='emptySeat.byTime !== "empty"'>
              <v-list-item
                v-for="item in emptySeat.byTime"
                :key="item.time"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.time }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    depressed
                    class='white--text'
                    color='green darken-2'
                    @click='confirm(item, SHIFT_TYPE.lesson.text)'
                    :disabled='isDisable(item.lesson, item.date, item.time)'
                  >
                    空：{{ item.lesson }}
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn
                    depressed
                    class='white--text'
                    color='blue darken-2'
                    @click='confirm(item, SHIFT_TYPE.free.text)'
                    :disabled='isDisable(item.free, item.date, item.time)'
                  >
                    空：{{ item.free }}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </div>
            <v-alert
              v-else
              type="error"
              icon="mdi-alert-outline"
              text
            >
              予約を受付けていません。
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- dialog -->
    <v-dialog v-model='dialog' persistent max-width='500px'>
      <v-form ref='form' v-model='valid' lazy-validation>
        <v-card>
          <v-card-title>
            <span class='headline'>予約内容</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                    予約日時
                  </p>
                  <v-text-field
                    v-model='reservationDate'
                    :value='reservationDate'
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                    種別
                  </p>
                  <v-text-field
                    v-model='editedItem.type'
                    :value='editedItem.type'
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                    担当インストラクター
                  </p>
                  <v-text-field
                    v-model='editedItem.instructor.name'
                    :value='editedItem.instructor.name'
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                    利用打席
                    <span class='required-label'>
                      必須
                    </span>
                  </p>
                  <p
                    class='mb-0 text--secondary v-messages'
                    style='line-height: 1.5;'
                  >
                    ご利用になる打席を選択してください。
                  </p>
                  <v-select
                    v-model='editedItem.category'
                    outlined
                    dense
                    :menu-props="{ top: true, offsetY: true }"
                    :items='editedItem.emptyCategory'
                    required
                    :rules='[formRules.required]'
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" dark outlined @click='close' :disabled="loading">
              <v-icon class='pr-1'>mdi-close</v-icon>
              キャンセル
            </v-btn>
            <v-btn color="blue darken-2" dark outlined @click='save' :loading="loading">
              <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
              予約する
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import lodash from 'lodash';
import moment from 'moment';
import util from '../util';
import config from '../mixins/config';
import Datepicker from '../components/Datepicker';
import ImportantNotification from '../components/ImportantNotification';

export default {
  components: {
    Datepicker,
    ImportantNotification,
  },
  mixins: [config],
  data: () => ({
    allShops: [],
    shops: [],
    selectShop: '',
    dialog: false,
    selectDay: moment(new Date()).format('YYYY-MM-DD'),
    today: moment(new Date()).format('YYYY-MM-DD'),
    currentTime: moment(),
    emptyInfo: [],
    editedItem: {
      instructor: {
        name: '',
      },
    },
    valid: true,
    // 入力規則
    formRules: {
      required: value => !!value || '必ず選択してください。',
    },
    loading: false,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
    userInfo() {
      return this.$store.getters.user;
    },
    reservationDate() {
      return moment(this.editedItem.date + this.editedItem.time, 'YYYY-MM-DDHH:mm').locale('ja').format('YYYY年MM月DD日(dd) HH:mm ~');
    },
  },
  watch: {
    async selectShop() {
      await this.getEmptySeat();
    },
    async selectDay() {
      await this.getEmptySeat();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.allShops = await util.callCloudFunction('getShop');
      this.shops = this.userInfo.shops.map(id => this.allShops.find(v => v.id === id));
      this.selectShop = this.shops[0];
    },
    async getEmptySeat() {
      const currentDay = await util.callCloudFunction('getEmptySheat', { date: this.selectDay, shopId: this.selectShop.id, lefty: this.userInfo.lefty, isNotMember: false });
      const nextDay = await util.callCloudFunction('getEmptySheat', { date: moment(this.selectDay).add(1, 'day').format('YYYY-MM-DD'), shopId: this.selectShop.id, lefty: this.userInfo.lefty, isNotMember: false });
      this.emptyInfo = [];
      this.emptyInfo.push(currentDay);
      this.emptyInfo.push(nextDay);
    },
    groupByInstructor(array) {
      const union = lodash.unionBy(array, 'uid').map(v => v.name);
      return lodash.join(lodash.pull(union, 'なし'), ', ');
    },
    confirm(item, type) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.type = type;
      // if (type === this.SHIFT_TYPE.free.text) {
      //   this.editedItem.instructorName = 'なし';
      //   this.editedItem.instructorNameKana = '';
      //   this.editedItem.instructorUid = '';
      // }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.$store.commit('snackbar/open', { text: '打席を選択してください。', color: 'error' });
        return;
      }
      this.loading = true;
      const param = {
        shop: this.selectShop,
        user: this.userInfo,
        date: this.editedItem.date,
        time: this.editedItem.time,
        type: this.editedItem.type,
        category: this.editedItem.category,
        instructor: this.editedItem.instructor,
      };
      await util.callCloudFunction('addReservation', param)
        .then(() => {
          this.$store.commit('snackbar/open', { text: '予約を正常に受付けました。', color: 'success' });
          util.callCloudFunction('sendReserveMail', param);
        }).catch(error => {
          this.$store.commit('snackbar/open', { text: error, color: 'error' });
        });
      // res = await reservation.add(param);
      // this.setSnackbar(res);
      await this.getEmptySeat();
      this.loading = false;
      this.close();
    },
    prev() {
      this.selectDay = moment(this.selectDay).add(-1, 'day').format('YYYY-MM-DD');
    },
    next() {
      this.selectDay = moment(this.selectDay).add(1, 'day').format('YYYY-MM-DD');
    },
    isDisable(item, date, time) {
      if (date === this.today) {
      // 開始時刻から20分以上経過で予約不可とする。
        const limit = 20;
        const entryTime = moment(date + time, 'YYYY-MM-DD HH:mm');
        return this.currentTime.diff(entryTime, 'minutes') >= limit || item === 0;
      }
      return item <= 0;
    },
    changeFormat(date) {
      return moment(date).locale('ja').format('M/D (dd)');
    },
    getSelectDay(key) {
      return moment(this.selectDay).add(key, 'day').locale('ja').format('M/D (dd)');
    },
    setSnackbar(res) {
      this.snackbarParam = res;
      this.snackbar = true;
    },
  },
};
</script>
<style>
.v-messages__message {
  line-height: 13px !important;
}
</style>
